import { IMAGE } from '../../assets/images/image';
import './lazy.scss';

const Lazy = () => {
	return (
		<div id="lazy-component">
			<IMAGE.TWEAK_LOGO width={50} height={50} className="lazy-logo" />
			<h4>TWEAK</h4>
		</div>
	);
};
export default Lazy;
