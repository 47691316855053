import "./notFound.scss";
const NotFound = () => {
  return (
    <div id="notFound">
      <div className="icon">&#128546;</div>
      <div className="text-notFound">
        {" "}
        ...Oops,
        <small> kayanya ada yang salah, coba scan ulang !!</small>
      </div>
    </div>
  );
};
export default NotFound;
