import { ReactComponent as TWEAK_LOGO } from "./tweak-points.svg";
import { ReactComponent as TWEAK_LOGO_BOTTOM } from "./tweak-bottom.svg";
import { ReactComponent as EMPTY } from "./Empty.svg";
import { ReactComponent as DEFAULT_PICTURE } from "./photo.svg";
import TWEAK_QR from "./QrLogo.png";

export const IMAGE = {
  EMPTY,
  TWEAK_LOGO,
  TWEAK_LOGO_BOTTOM,
  DEFAULT_PICTURE,
  TWEAK_QR,
};
