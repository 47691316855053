// import logo from './logo.svg';
import React, { Suspense } from "react";
import "./App.scss";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./component/layout/layout";
import Lazy from "./component/lazy/lazy";
import NotFound from "./component/notFound/notFound";

const FormSignIn = React.lazy(() => import("./pages/formSignIn"));

const Home = React.lazy(() => import("./pages/home"));
const Detail = React.lazy(() => import("./pages/detail"));
const FormSignInTrainer = React.lazy(() =>
  import("./pages/formSignInTrainer/index")
);
const DetailTrainer = React.lazy(() => import("./pages/detailTrainer/index"));

function App() {
  return (
    <div className="app">
      <Router>
        <Layout>
          <Suspense fallback={<Lazy />}>
            <Routes>
              <Route exact path="/:id" element={<FormSignIn />} />

              <Route path="/tweakpass/home" element={<Home />} />
              <Route path="/tweakpass/detail" element={<Detail />} />
              {/* trainer */}
              <Route path="/check-in/trainer" element={<FormSignInTrainer />} />

              <Route exact path="/qr-trainer" element={<DetailTrainer />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </Layout>
      </Router>
    </div>
  );
}

export default App;
